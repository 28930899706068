import React from 'react';
import { Box } from '@mui/material';
import { formatDate, formatDigitValue, SortConfig } from '../../../utils/Utils';
import { getSafeImage } from '../../../utils/MapUtils';
import { detailStyle, parametersStyle, parameterItemStyle, parameterTextStyle } from './Style';
import Text from '../../common/text/Text';
import { useAuth } from '../../../context/auth-context/AuthContext';

interface PopupParameterProps {
    selectedStation: any;
    sortedParameters: any[];
    sortConfig: SortConfig;
    setSortConfig: React.Dispatch<React.SetStateAction<SortConfig>>;
}

const WaterQualityParam: React.FC<PopupParameterProps> = ({ selectedStation, sortedParameters, sortConfig, setSortConfig }) => {
    const { user } = useAuth();

    const userRoles = Array.isArray(user?.roles) ? user.roles : [user?.roles];

    const arrangeParameters = (parameters: any[]) => {
        const firstRow = ['Chlorophyll-A', 'DO', 'Salinity', 'Temperature'];
        const secondRow = ['pH', 'Conductivity', 'Turbidity'];

        if (userRoles.includes('DHIAdmin') || userRoles.includes('SuperAdministrator')) {
            secondRow.push('Battery');
        }

        const firstRowParams = parameters.filter(param => firstRow.includes(param.name));
        const secondRowParams = parameters.filter(param => secondRow.includes(param.name)).reverse();

        return [...firstRowParams, ...secondRowParams];
    };

    const arrangedParameters = arrangeParameters(sortedParameters);

    return (
        <Box sx={{ padding: 1, width: '100%' }}>
            {arrangedParameters.length > 0 ? (
                <Box sx={detailStyle}>
                    <Text variant="caption" sx={{ color: '#86A2B3' }}>Parameters:</Text>
                    <Box sx={parametersStyle}>
                        {arrangedParameters.map((param: any, index: number) => (
                            <Box key={index} sx={parameterItemStyle}>
                                <Box sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: 1 }}>
                                    <img
                                        src={getSafeImage(param.isOnline ? (param.isWithinThresholdLimits ? 'Online' : 'Warning') : param.isWithinThresholdLimits ? 'Offline' : 'Warning', param.name)}
                                        alt={`${param.name} icon`}
                                        style={{ height: 25 }}
                                    />
                                </Box>
                                <Box sx={parameterTextStyle}>
                                    <Text
                                        variant="caption"
                                        sx={{ color: '#9B9B9B', fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
                                    >
                                        {param.name}
                                    </Text>
                                    <Text
                                        variant="caption"
                                        sx={{ color: '#09334B', fontSize: 12, fontWeight: 'bold' }}
                                    >
                                        {param.latestValue !== null && param.latestValue !== undefined ? (
                                            <React.Fragment>
                                                {formatDigitValue(param.latestValue, selectedStation.dataType)}{' '}{param.unit}
                                            </React.Fragment>
                                        ) : (
                                            ' - '
                                        )}
                                    </Text>
                                    <Text
                                        variant="caption"
                                        sx={{ color: '#9B9B9B', fontSize: 10, textAlign: 'left' }}
                                    >
                                        {formatDate(param.lastUpdatedDateTime)}
                                    </Text>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ) : (
                <Box>No data available</Box>
            )}
        </Box>
    );
};

export default WaterQualityParam;
