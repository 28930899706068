import React from 'react';
import { Box } from '@mui/material';
import { formatDigitValue } from '../../../utils/Utils';
import { getSafeImage } from '../../../utils/MapUtils';
import { detailStyle, parametersStyle, parameterItemStyle, parameterTextStyle } from './Style';
import Text from '../../common/text/Text';

interface PopupParameterProps {
    selectedStation: any;
    sortedParameters: any[];
}

const NutrientSurveyParam: React.FC<PopupParameterProps> = ({ selectedStation, sortedParameters }) => {
    return (
        <Box sx={{ padding: 1, width: '100%' }}>
            {sortedParameters.length > 0 ? (
                <Box sx={detailStyle}>
                    <Text variant="caption" sx={{ color: '#86A2B3' }}>Parameters:</Text>
                    <Box sx={parametersStyle}>
                        {sortedParameters.map((param: any) => (
                            <Box key={param.id || param.name} sx={parameterItemStyle}>
                                <Box
                                    sx={{
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginRight: 1,
                                    }}
                                >
                                    <img
                                        src={getSafeImage(
                                            param.isOnline
                                                ? param.isWithinThresholdLimits
                                                    ? 'Online'
                                                    : 'Warning'
                                                : param.isWithinThresholdLimits
                                                    ? 'Offline'
                                                    : 'Warning',
                                            param.name
                                        )}
                                        alt={`${param.name} icon`}
                                        style={{ height: 25 }}
                                    />
                                </Box>
                                <Box sx={parameterTextStyle}>
                                    <Text
                                        variant="caption"
                                        sx={{
                                            color: '#9B9B9B',
                                            fontSize: 11,
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {param.name}
                                    </Text>
                                    <Text
                                        variant="caption"
                                        sx={{ color: '#09334B', fontSize: 12, fontWeight: 'bold' }}
                                    >
                                        {param.latestValue !== null && param.latestValue !== undefined ? (
                                            <React.Fragment>
                                                {formatDigitValue(param.latestValue, selectedStation.dataType)}{' '}{param.unit}
                                            </React.Fragment>
                                        ) : (
                                            ' - '
                                        )}
                                    </Text>
                                </Box>
                            </Box>
                        ))}

                    </Box>
                </Box>
            ) : (
                <Box>No data available</Box>
            )}
        </Box>
    );
};

export default NutrientSurveyParam;
