import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { Box } from '@mui/material';
import { useMapView } from '../../../context/map-context/mapContext';
import { useLocation, useNavigate } from 'react-router-dom';
import chartIcon from '../../../asset/Icon/Popup/chart.svg';
import mapIcon from '../../../asset/Icon/Popup/map.svg';
import { boxStyle } from './Style';
import PopupHeader from './PopupHeader';
import PopupInfo from './PopupInfo';
import PopupRemark from './PopupRemark';
import PopupFooter from './PopupFooter';
import { CustomPopupProps, dataType } from '../../../pages/map-view-page/MapViewLib';
import { Close } from '@mui/icons-material';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';
import { SortConfig } from '../../../utils/EventUtils';
import { StationInfo } from '../../../pages/time-series-page/TimeseriesLib';
import PlanktonSurveyParam from './PlanktonSurveyParam';
import NutrientSurveyParam from './NutrientSurveyParam';
import SedimentQualityParam from './SedimentQualityParam';
import WaterQualityParam from './WaterQualityParam';

const CustomPopup: React.FC<CustomPopupProps> = ({ onClose }) => {

    const { selectedStation, showMenu } = useMapView();
    const { setStationInfo } = useTimeseriesView();
    const navigate = useNavigate();
    const location = useLocation();

    const popupRef = useRef<HTMLDivElement>(null);
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'defaultKey', direction: 'ascending' });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const menuWidth = showMenu ? 450 : 0;

    const calculateInitialPosition = () => {
        const popupWidth = popupRef.current?.offsetWidth || 0;
        const popupHeight = popupRef.current?.offsetHeight || 0;
        return {
            x: (window.innerWidth - popupWidth - menuWidth) / 2,
            y: (window.innerHeight - popupHeight) / 2,
        };
    };

    useEffect(() => {
        setPosition(calculateInitialPosition());

        const handleResize = () => {
            setPosition(calculateInitialPosition());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onDrag = (e: any, data: any) => {
        setPosition({ x: data.x, y: data.y });
    };

    const icon = location.pathname === '/timeseries' ? mapIcon : chartIcon;
    const label = location.pathname === '/timeseries' ? 'Map' : 'Chart';

    const handleClickChartButton = (selectedStation: any) => {
        if (selectedStation) {
            setStationInfo((prevState: StationInfo) => ({
                ...prevState,
                selectedDataType: selectedStation.dataType,
                selectedArea: selectedStation.area,
                selectedStation: [selectedStation.stationId],
                selectedParameters: [],
            }));
        }

        navigate(location.pathname === '/timeseries' ? '/map-view' : '/timeseries');
    };

    const sortedParameters = React.useMemo(() => {
        if (!selectedStation?.parameters) {
            return [];
        }
        const sortableItems = [...selectedStation.parameters];
        if (sortConfig.key && sortConfig.direction) {
            sortableItems.sort((a, b) => {
                const key = sortConfig.key as keyof typeof a;
                if (a[key] < b[key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [selectedStation?.parameters, sortConfig]);

    if (!selectedStation) {
        return null;
    }

    const renderParameter = (selectedDataType: string) => {
        switch (selectedDataType) {
            case dataType.WaterQuality:
                return <WaterQualityParam selectedStation={selectedStation} sortedParameters={sortedParameters} sortConfig={sortConfig} setSortConfig={setSortConfig} />
            case dataType.SedimentQuality:
                return <SedimentQualityParam selectedStation={selectedStation} sortedParameters={sortedParameters} />
            case dataType.PlanktonSurvey:
                return <PlanktonSurveyParam selectedStation={selectedStation} sortedParameters={sortedParameters} sortConfig={sortConfig} setSortConfig={setSortConfig} />
            case dataType.NutrientSurveyResult:
                return <NutrientSurveyParam selectedStation={selectedStation} sortedParameters={sortedParameters} />
            default:
                return null
        }
    }

    return (
        <Draggable onDrag={onDrag} position={position} cancel='.no-drag'>
            <Box
                ref={popupRef}
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '80%',
                    maxWidth: '620px',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                }}
            >
                <Box sx={{ ...boxStyle, width: '100%' }}>
                    <PopupHeader />
                    <PopupInfo />
                    {renderParameter(selectedStation?.dataType)}
                    {selectedStation?.dataType !== dataType.WaterQuality && <PopupRemark />}
                    <PopupFooter onClick={() => handleClickChartButton(selectedStation)} icon={icon} label={label} />
                </Box>
                <Close className='no-drag' sx={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }} onClick={onClose} />
            </Box>
        </Draggable>
    );
};

export default CustomPopup;
