import React from 'react';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { handleSort, formatDigitValue, SortConfig } from '../../../utils/Utils';

interface PopupParameterProps {
    selectedStation: any;
    sortedParameters: any[];
    sortConfig: SortConfig;
    setSortConfig: React.Dispatch<React.SetStateAction<SortConfig>>;
}

const PlanktonSurveyParam: React.FC<PopupParameterProps> = ({ selectedStation, sortedParameters, sortConfig, setSortConfig }) => {
    return (
        <Box sx={{ padding: 1, width: '100%' }}>
            <TableContainer component={Paper} sx={{ maxHeight: 200, overflow: 'auto', width: '100%' }}>
                <Table sx={{ width: '100%' }} aria-label="parameter table" stickyHeader>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell sx={{ padding: 1, fontSize: '12px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('name', sortConfig, setSortConfig)}>
                                Species
                                {sortConfig.key === 'name' ? (sortConfig.direction === 'ascending' ? <ArrowDropUp sx={{ ml: '2px', fontSize: '16px' }} /> : <ArrowDropDown sx={{ ml: '2px', fontSize: '16px' }} />) : ''}
                            </TableCell>
                            <TableCell align="right" sx={{ padding: 1, fontSize: '12px', fontWeight: 'bold' }}>Value (cell/mL)</TableCell>
                            <TableCell sx={{ padding: 1, fontSize: '12px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('speciesType', sortConfig, setSortConfig)}>
                                Type
                                {sortConfig.key === 'speciesType' ? (sortConfig.direction === 'ascending' ? <ArrowDropUp sx={{ ml: '2px', fontSize: '16px' }} /> : <ArrowDropDown sx={{ ml: '2px', fontSize: '16px' }} />) : ''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedParameters.length > 0 ? (
                            sortedParameters?.map((param: any) => (
                                <TableRow
                                    key={param.name}>
                                    <TableCell sx={{ padding: 1, fontSize: '10px' }}>
                                        {param.name}
                                    </TableCell>
                                    <TableCell align="right" sx={{ padding: 1, fontSize: '10px', color: param.isWithinThresholdLimits ? 'black' : 'red' }}>
                                        {param.latestValue !== null && param.latestValue !== undefined ? (
                                            <React.Fragment>
                                                {formatDigitValue(param.latestValue, selectedStation.dataType)}{' '}{param.unit}
                                            </React.Fragment>
                                        ) : (
                                            ' - '
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ padding: 1, fontSize: '10px' }}>{param?.speciesType}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow >
                                <TableCell colSpan={4} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default PlanktonSurveyParam;
