import React from 'react';
import { Box } from '@mui/material';
import { formatDigitValue } from '../../../utils/Utils';
import { detailStyle, parametersStyle, parameterItemStyle, parameterTextStyle } from './Style';
import Text from '../../common/text/Text';

interface PopupParameterProps {
    selectedStation: any;
    sortedParameters: any[];
}

const SedimentQualityParam: React.FC<PopupParameterProps> = ({ selectedStation, sortedParameters }) => {
    const modifyParametersForSedimentQuality = (parameters: any[]) => {
        return parameters.map((param, index) => {
            if (index === 0) {
                return { ...param, name: 'Temperature' };
            }
            if (index === 1) {
                return { ...param, name: 'ORP' };
            }
            if (index === 2) {
                return { ...param, name: 'Sulphide (mg/kg)' }
            }
            if (index === 3) {
                return { ...param, name: 'Sulphide (µmol/L)' }
            }
            return param;
        });
    };

    const arrangedParameters = modifyParametersForSedimentQuality(sortedParameters)

    return (
        <Box sx={{ padding: 1, width: '100%' }}>
            {arrangedParameters.length > 0 ? (
                <Box sx={detailStyle}>
                    <Text variant="caption" sx={{ color: '#86A2B3' }}>Parameters:</Text>
                    <Box sx={parametersStyle}>
                        {arrangedParameters.map((param: any, index: number) => (
                            <Box key={index} sx={parameterItemStyle}>
                                <Box sx={parameterTextStyle}>
                                    <Text
                                        variant="caption"
                                        sx={{ color: '#9B9B9B', fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
                                    >
                                        {param.name}
                                    </Text>
                                    <Text
                                        variant="caption"
                                        sx={{ color: '#09334B', fontSize: 12, fontWeight: 'bold' }}
                                    >
                                        {param.latestValue !== null && param.latestValue !== undefined ? (
                                            <React.Fragment>
                                                {formatDigitValue(param.latestValue, selectedStation.dataType)}{' '}{param.unit}
                                            </React.Fragment>
                                        ) : (
                                            ' - '
                                        )}
                                    </Text>
                                </Box>
                            </Box>
                        ))}
                        {selectedStation?.dataType === 'SedimentQuality' && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 2 }}>
                                <Box sx={parameterItemStyle}>
                                    <Box sx={parameterTextStyle}>
                                        <Text
                                            variant="caption"
                                            sx={{ color: '#9B9B9B', fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
                                        >
                                            Sulphur Odour:
                                        </Text>
                                        <Text variant="caption" sx={{ color: '#09334B', fontSize: 12, fontWeight: 'bold' }}>
                                            {selectedStation?.sulphureOdour ?? ' - '}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box sx={parameterItemStyle}>
                                    <Box sx={parameterTextStyle}>
                                        <Text
                                            variant="caption"
                                            sx={{ color: '#9B9B9B', fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
                                        >
                                            Sediment Type:
                                        </Text>
                                        <Text variant="caption" sx={{ color: '#09334B', fontSize: 12, fontWeight: 'bold' }}>
                                            {selectedStation?.sedimentType ?? ' - '}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box>No data available</Box>
            )}
        </Box>
    );
};

export default SedimentQualityParam;
