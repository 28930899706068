import { Box } from '@mui/material'
import Text from '../../common/text/Text';
import React from 'react'
import { detailStyle, infoStyle, sensorImageStyle } from './Style';
import { dataType } from '../../../pages/map-view-page/MapViewLib';
import { useMapView } from '../../../context/map-context/mapContext';

const PopupInfo = () => {
    const { selectedStation } = useMapView()
    return (
        <Box sx={infoStyle}>
            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <Box sx={detailStyle}>
                    <Text variant="caption" sx={{ color: '#86A2B3' }}>Latitude:</Text>
                    <Text variant="caption" sx={{ color: '#0B4566' }}>{selectedStation?.latitude.toFixed(4)}</Text>
                </Box>
                <Box sx={detailStyle}>
                    <Text variant="caption" sx={{ color: '#86A2B3' }}>Longitude:</Text>
                    <Text variant="caption" sx={{ color: '#0B4566' }}>{selectedStation?.longitude.toFixed(4)}</Text>
                </Box>
                {selectedStation?.dataType !== dataType.WaterQuality && (
                    <Box sx={detailStyle}>
                        <Text variant="caption" sx={{ color: '#86A2B3' }}>Weather:</Text>
                        <Text variant="caption" sx={{ color: '#0B4566' }}>{selectedStation?.weatherCondition}</Text>
                    </Box>
                )}
            </Box>
            {selectedStation?.dataType === dataType.WaterQuality && selectedStation?.stationImage !== null && (
                <Box component="img" src={`data:image/png;base64,${selectedStation?.stationImage}`} alt={`${selectedStation?.stationId}-image`} sx={sensorImageStyle} />
            )}
        </Box>
    )
}

export default PopupInfo