import React from 'react'
import { Box } from '@mui/material'
import Text from '../../common/text/Text';
import { remarksStyle } from './Style';
import { useMapView } from '../../../context/map-context/mapContext';

const PopupRemark = () => {
    const {selectedStation} = useMapView()
    return (
        <Box sx={remarksStyle}>
            <Text variant="caption" sx={{ color: '#86A2B3', mr: 1 }}>Remarks:</Text>
            <Text variant="caption" sx={{ color: '#0B4566' }}>{selectedStation?.remarks}</Text>
        </Box>
    )
}

export default PopupRemark