import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, SelectChangeEvent, useMediaQuery } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';
import { useChartOptions } from '../../../hooks/useChartOptions';
import {
    handleAreaChange,
    handleDataTypeChange,
    handleExportData,
    handleExportRawData,
    handleFetchTimeseries,
    handleParameterChange,
    handlePeriodChange,
    handleResetTimeseries,
    handleStationChange,
    updateDates
} from '../../../utils/TimeseriesUtils';
import { useAuth } from '../../../context/auth-context/AuthContext';
import {
    PeriodSelector,
    DataTypeSelector,
    AreaSelector,
    StationSelector,
    ParameterSelector,
    StatisticTypeSelector
} from './Selectors';
import { getDataTypeData } from './TimeseriesData';
import { useMapView } from '../../../context/map-context/mapContext';
import { handleHighlightLayer } from '../../../utils/MapUtils';
import { showSnackbar } from '../../../utils/Utils';
import ButtonWithIcon from '../../common/button/Button';
import Text from '../../common/text/Text';
import { Observations } from '../../../pages/time-series-page/TimeseriesLib';
import DataView from './DataView';
import { dataType } from '../../../pages/map-view-page/MapViewLib';

interface TimeseriesPanelProps {
    panelType: 'Observation' | 'Monthly';
  }
  
  const TimeseriesPanel: React.FC<TimeseriesPanelProps> = ({ panelType }) => {

    const { baseUrl, user, setVariant, setSnackbarOpen, setSnackbarMessage } = useAuth();
    const { setHighlightLayer } = useMapView()
    const {
        stationInfo,
        setStationInfo,
        fetchObservationsData,
        fetchMonthlyStatisticData,
        exportObservationData,
        exportMonthlyStatisticData,
        fetchAreaList,
        fetchStationList,
        fetchParameterList,
        fetchStatisticTypeList,
        fetchDataTypeList,
        loading,
        dataTypeList,
        statisticTypeList,
    } = useTimeseriesView();

    const [data, setData] = useState<Observations[]>([]);
    const [areaList, setAreaList] = useState<string[]>([])
    const [stationList, setStationList] = useState<string[]>([])
    const [parameterList, setParameterList] = useState<string[]>([])
    const [selectedPeriod, setSelectedPeriod] = useState<string>(panelType === 'Observation' ? (stationInfo?.selectedDataType === 'WaterQuality' ? 'Last 24 Hours' : 'Last 1 Year') : 'Last 1 Year');
    const [selectedDataType, setSelectedDataType] = useState<string>('WaterQuality');
    const [selectedArea, setSelectedArea] = useState<string>('');
    const [selectedStation, setSelectedStation] = useState<string[]>([]);
    const [selectedParameters, setSelectedParameters] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [customStartDate, setCustomStartDate] = useState<string>('');
    const [customEndDate, setCustomEndDate] = useState<string>('');
    const [selectedStatisticType, setSelectedStatisticType] = useState<number>(0);
    const [showThreshold, setShowThreshold] = useState<boolean>(false);
    const [isMoveAxis, setIsMoveAxis] = useState<boolean>(false);
    const [isShowDataView, setIsShowDataView] = useState<boolean>(false);
    const [zoomRange, setZoomRange] = useState({ start: 0, end: 100 });

    const isSmallScreen = useMediaQuery('(max-width: 1000px)');

    useEffect(() => {
        if (stationInfo) {
            setSelectedDataType(stationInfo.selectedDataType);
            setSelectedArea(stationInfo.selectedArea);
            setSelectedStation(stationInfo.selectedStation);
            setSelectedParameters(stationInfo.selectedParameters);
            setData([])
            setIsShowDataView(false)
        }
    }, []);

    useEffect(() => {
        if (baseUrl && user?.token) {
            fetchDataTypeList()
            fetchAreaList(stationInfo.selectedDataType, setAreaList)
            const areaParam = stationInfo.selectedDataType === dataType.WaterQuality ? '' : stationInfo.selectedArea;
            fetchStationList(stationInfo.selectedDataType, areaParam, setStationList);
            if (stationInfo.selectedStation.length !== 0) {
                fetchParameterList(stationInfo.selectedStation, setParameterList)
            }
            if (panelType === 'Monthly') {
                fetchStatisticTypeList(stationInfo.selectedDataType)
            }
            setIsShowDataView(false)
        }
    }, [baseUrl, user?.token, selectedDataType, panelType]);

    useEffect(() => {
        if (selectedPeriod) {
            updateDates(selectedPeriod, customStartDate, customEndDate, setStartDate, setEndDate);
        }
    }, [selectedPeriod, customStartDate, customEndDate]);

    const handleZoomChange = (zoom: any) => {
        setZoomRange({ start: zoom.start, end: zoom.end });
    };

    const {
        getOption
    } = useChartOptions(
        data,
        selectedDataType,
        selectedStation,
        selectedParameters,
        showThreshold,
        isMoveAxis,
        setShowThreshold,
        setIsMoveAxis,
        setIsShowDataView,
        () => handleExportData(
            panelType,
            startDate,
            endDate,
            selectedDataType,
            selectedStation,
            selectedParameters,
            exportObservationData,
            exportMonthlyStatisticData,
            selectedStatisticType
        ),
        () => handleExportRawData(
            startDate,
            endDate,
            selectedDataType,
            selectedStation,
            selectedParameters,
            exportObservationData
        ),
        handleZoomChange,
        zoomRange
    );

    const filteredDataTypeList = getDataTypeData(user)

    return (
        <Box sx={{ width: '100%', flexWrap: 'wrap' }}>
            <PeriodSelector
                panelType={panelType}
                selectedPeriod={selectedPeriod}
                handlePeriodChange={(event: SelectChangeEvent<string>) =>
                    handlePeriodChange(
                        event,
                        setSelectedPeriod,
                        startDate,
                        endDate,
                        setStartDate,
                        setEndDate
                    )
                }
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" gap={1} flexWrap={'wrap'}>
                <DataTypeSelector
                    selectedDataType={selectedDataType}
                    handleDataTypeChange={(event: SelectChangeEvent) => {
                        handleDataTypeChange(
                            event,
                            panelType,
                            selectedArea,
                            setSelectedPeriod,
                            setSelectedDataType,
                            setSelectedArea,
                            setSelectedStation,
                            setSelectedParameters,
                            stationInfo,
                            setStationInfo,
                            fetchStatisticTypeList,
                            fetchAreaList,
                            fetchStationList,
                            setData,
                            setAreaList,
                            setStationList
                        )
                    }}
                    filteredDataTypeList={filteredDataTypeList}
                />
                {selectedDataType !== dataType.WaterQuality && (
                    <AreaSelector
                        selectedArea={selectedArea}
                        handleAreaChange={(event: SelectChangeEvent) => {
                            handleAreaChange(
                                event,
                                selectedDataType,
                                setSelectedArea,
                                stationInfo,
                                setStationInfo,
                                fetchStationList,
                                setData,
                                setStationList
                            )
                        }}
                        areaList={areaList}
                    />
                )}
                {(selectedDataType === dataType.WaterQuality || selectedArea) && (
                    <StationSelector
                        selectedStation={selectedStation}
                        handleStationChange={(event: SelectChangeEvent) => {
                            handleStationChange(
                                event,
                                setSelectedStation,
                                setHighlightLayer,
                                handleHighlightLayer,
                                stationInfo,
                                setStationInfo,
                                fetchParameterList,
                                setData,
                                setParameterList
                            )
                        }
                        }
                        stationList={stationList}
                    />
                )}
                {(selectedStation.length > 0) && (
                    <ParameterSelector
                        panelType={panelType}
                        selectedParameters={selectedParameters}
                        handleParameterChange={(event: SelectChangeEvent<string[]>) =>
                            handleParameterChange(
                                event,
                                setSelectedParameters,
                                setVariant,
                                setSnackbarOpen,
                                setSnackbarMessage,
                                showSnackbar,
                                stationInfo,
                                setStationInfo,
                                setData
                            )
                        }
                        parameterList={parameterList}
                    />
                )}
                {panelType === 'Monthly' && selectedParameters.length > 0 && (
                    <StatisticTypeSelector
                        selectedStatisticType={selectedStatisticType}
                        setSelectedStatisticType={setSelectedStatisticType}
                        statisticTypeList={statisticTypeList}
                    />
                )}
                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" gap={1}>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Text variant="caption" sx={{ color: 'transparent', fontSize: isSmallScreen ? 12 : 14 }}>Reset</Text>
                        <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <ButtonWithIcon
                                variant='outlined'
                                icon={null}
                                onClick={() =>
                                    handleResetTimeseries(
                                        panelType,
                                        setSelectedDataType,
                                        setSelectedArea,
                                        setSelectedPeriod,
                                        setSelectedStation,
                                        setSelectedParameters,
                                        setStationInfo,
                                        setHighlightLayer,
                                        setData
                                    )}
                                text='Reset'
                                sx={{
                                    color: '#f58e3f',
                                    backgroundColor: '#FFF',
                                    borderColor: '#f58e3f',
                                    borderRadius: 1,
                                    fontSize: isSmallScreen ? 11 : 14,
                                    height: isSmallScreen ? 30 : 40,
                                    width: isSmallScreen ? 60 : 80,
                                    ':hover': {
                                        color: '#FFF',
                                        backgroundColor: '#f58e3f',
                                        borderColor: '#f58e3f',
                                        opacity: 0.9
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Text variant="caption" sx={{ color: 'transparent', fontSize: isSmallScreen ? 12 : 14 }}>Apply</Text>
                        <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <ButtonWithIcon
                                variant='contained'
                                icon={null}
                                onClick={() =>
                                    handleFetchTimeseries(
                                        startDate,
                                        endDate,
                                        selectedDataType,
                                        selectedStation,
                                        selectedParameters,
                                        fetchObservationsData,
                                        fetchMonthlyStatisticData,
                                        panelType,
                                        setData,
                                        dataTypeList,
                                        selectedStatisticType
                                    )
                                }
                                text='Apply'
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#f58e3f',
                                    borderColor: '#f58e3f',
                                    borderRadius: 1,
                                    fontSize: isSmallScreen ? 11 : 14,
                                    height: isSmallScreen ? 30 : 40,
                                    width: isSmallScreen ? 60 : 80,
                                    ':hover': {
                                        color: '#fff',
                                        backgroundColor: '#f58e3f',
                                        borderColor: '#f58e3f',
                                        opacity: 0.9
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box position="relative">
                {loading && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        zIndex={5}
                        bgcolor="rgba(255, 255, 255, 0.7)"
                    >
                        <CircularProgress />
                    </Box>
                )}
                <ReactECharts
                    key={`${data.length}-${showThreshold}-${isMoveAxis}-${selectedPeriod}`}
                    option={getOption() || {}}
                    style={{
                        height: '550px',
                        width: '100%',
                        border: '1px solid lightgray',
                        borderRadius: 2,
                        padding: 2
                    }}
                />
                {data.length > 0 && isShowDataView && (
                    <DataView data={data} onClose={() => setIsShowDataView(false)} />
                )}
            </Box>
        </Box>
    );
};

export default TimeseriesPanel;