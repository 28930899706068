export type Viewport = {
    longitude: number,
    latitude: number,
    zoom: number,
    pitch: number,
    bearing: number,
}

export type CursorPositionProps = {
    latitude: number,
    longitude: number,
}

export type MenuItem = {
    name: string;
    icon: string;
    layerId: string;
    latitude: number;
    longitude: number;
    parameter: string;
    unitOfMeasurements: string;
    waterDepth: number;
    image: string;
}

export type SubGroup = {
    name: string;
    menuItems: MenuItem[];
}

export type Group = {
    id: number;
    name: string;
    subGroups?: SubGroup[];
    menuItems?: MenuItem[];
}

export type LayerCollapseProps = {
    groups: Group[];
}

export type CustomPopupProps = {
    onClose: () => void;
}

export enum dataType {
    WaterQuality = 'WaterQuality',
    SedimentQuality = 'SedimentQuality',
    PlanktonSurvey = 'PlanktonSurvey',
    NutrientSurveyResult = 'NutrientSurveyResult'
}