import React from 'react';
import { Box, MenuItem, useMediaQuery } from '@mui/material';
import Text from '../../common/text/Text';
import ButtonWithIcon from '../../common/button/Button';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { useEventView } from '../../../context/event-context/EventContext';
import { useAuth } from '../../../context/auth-context/AuthContext';

type CreateEventProps = {
    onClose: () => void;
}

interface FormValues {
    assignedUserId: string | null;
    startedDateTime: string;
    completedDateTime: string | null;
    type: number;
    status: number;
    description: string;
    remarks: string;
}

const validationSchema = Yup.object({
    assignedUserId: Yup.string().nullable(),
    startedDateTime: Yup.date().required('Started Date is required'),
    completedDateTime: Yup.date().nullable().notRequired(),
    type: Yup.number().required('Type is required'),
    status: Yup.number().required('Status is required'),
    description: Yup.string().required('Description is required'),
    remarks: Yup.string(),
});

const CreateEvent: React.FC<CreateEventProps> = ({ onClose }) => {

    const { status, eventType, assignedToList, createEvent } = useEventView();
    const { user } = useAuth()
    const isMobile = useMediaQuery('(min-width: 600px)');

    const initialValues: FormValues = {
        assignedUserId: null,
        startedDateTime: '',
        completedDateTime: null,
        type: 0,
        status: 0,
        description: '',
        remarks: '',
    };

    const onSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        const newEvent = {
            type: values.type,
            description: values.description,
            startedDateTime: new Date(values.startedDateTime).toISOString(),
            completedDateTime: values.completedDateTime ? new Date(values.completedDateTime).toISOString() : null,
            createdBy: user?.name,
            status: values.status,
            remarks: values.remarks,
            assignedUserId: values.assignedUserId,
            reportedDateTime: new Date().toISOString()
        };
        createEvent(newEvent);
        resetForm();
        onClose();
    };

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 50,
                left: 0,
                zIndex: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flex: 1,
                height: 'auto',
                width: '100%',
                maxWidth: 400,
                backgroundColor: 'white',
                color: '#0B4566',
                padding: 2,
                boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`
            }}
        >
            <Text
                variant='caption'
                sx={{
                    fontSize: 16,
                    fontWeight: '500',
                    marginBottom: 2,
                }}
            >
                Create New Event
            </Text>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => (
                    <Form style={{ width: '100%' }}>
                        <Box sx={{ marginBottom: 2, textAlign: 'left' }}>
                            <Field
                                component={TextField}
                                name="assignedUserId"
                                label="Assigned To"
                                select
                                variant="filled"
                                fullWidth
                                InputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{ style: { fontSize: 12 } }}
                            >
                                <MenuItem value={undefined}>
                                    - select user -
                                </MenuItem>
                                {assignedToList.map((item, index) => {
                                    const displayAssignName = `${item.firstName} ${item.lastName}`;
                                    return (
                                        <MenuItem key={index} value={item.id}>
                                            {displayAssignName}
                                        </MenuItem>
                                    );
                                })}
                            </Field>

                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'row' : 'column',
                                gap: 2,
                                marginBottom: 2
                            }}
                        >
                            <Field
                                component={TextField}
                                name="startedDateTime"
                                label="Start Date"
                                type="datetime-local"
                                InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                                InputProps={{ style: { fontSize: 12 } }}
                                variant="filled"
                                fullWidth
                            />
                            <Field
                                component={TextField}
                                name="completedDateTime"
                                label="End Date"
                                type="datetime-local"
                                InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                                InputProps={{ style: { fontSize: 12 } }}
                                variant="filled"
                                fullWidth
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'row' : 'column',
                                gap: 2,
                                marginBottom: 2,
                                textAlign: 'left'
                            }}>
                            <Field
                                component={TextField}
                                name="type"
                                label="Type"
                                select
                                variant="filled"
                                fullWidth
                                InputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{ style: { fontSize: 12 } }}
                            >
                                {eventType?.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Field>
                            <Field
                                component={TextField}
                                name="status"
                                label="Status"
                                select
                                variant="filled"
                                fullWidth
                                InputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{ style: { fontSize: 12 } }}
                            >
                                {status?.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <Field
                                component={TextField}
                                name="description"
                                label="Description"
                                multiline
                                rows={2}
                                variant="filled"
                                fullWidth
                                InputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{ style: { fontSize: 12 } }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <Field
                                component={TextField}
                                name="remarks"
                                label="Remark"
                                multiline
                                rows={2}
                                variant="filled"
                                fullWidth
                                InputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{ style: { fontSize: 12 } }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                width: '100%',
                                marginTop: 1,
                            }}
                        >
                            <ButtonWithIcon
                                variant='contained'
                                type='button'
                                onClick={onClose}
                                icon={null}
                                text='Cancel'
                                sx={{
                                    backgroundColor: 'lightgray',
                                    borderRadius: 2,
                                    ':hover': {
                                        backgroundColor: 'lightgray',
                                        opacity: 0.8,
                                    },
                                }}
                            />
                            <ButtonWithIcon
                                variant='contained'
                                type='submit'
                                icon={null}
                                text='Create'
                                sx={{
                                    backgroundColor: '#f58e3f',
                                    borderRadius: 2,
                                    ':hover': {
                                        backgroundColor: '#d47606',
                                        opacity: 0.8
                                    },
                                    marginLeft: 2
                                }}
                            />
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateEvent;
