import React, { useState } from 'react';
import { formatDigitValue } from '../../../utils/Utils';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, TableSortLabel } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { getSeriesName } from '../../../utils/TimeseriesUtils';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';
import { dataType } from '../../../pages/map-view-page/MapViewLib';

const DataView = ({ data, onClose }: any) => {
    const [sortConfig, setSortConfig] = useState<{ column: number, direction: 'asc' | 'desc' } | null>(null);
    const { statisticTypeList } = useTimeseriesView();

    const handleSort = (columnIndex: number) => {
        if (sortConfig && sortConfig.column === columnIndex) {
            setSortConfig({
                column: columnIndex,
                direction: sortConfig.direction === 'asc' ? 'desc' : 'asc'
            });
        } else {
            setSortConfig({ column: columnIndex, direction: 'asc' });
        }
    };

    const sortData = (dataPoints: string[]) => {
        if (!sortConfig) return dataPoints;

        const { column, direction } = sortConfig;

        if (column === -1) {
            return [...dataPoints].sort((a, b) => {
                const dateA = new Date(a).getTime();
                const dateB = new Date(b).getTime();
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
            });
        }

        const sortedData = [...dataPoints].sort((a, b) => {
            const valueA = data[column]?.data.find((point: any) => point.dateTime === a)?.value || 0;
            const valueB = data[column]?.data.find((point: any) => point.dateTime === b)?.value || 0;

            return direction === 'asc' ? valueA - valueB : valueB - valueA;
        });

        return sortedData;
    };

    const allDates: string[] = Array.from(
        new Set(
            data.reduce((acc: string[], timeseries: any) => {
                const seriesDates: string[] = timeseries.data.map((point: any) => point.dateTime);
                return [...acc, ...seriesDates];
            }, [])
        )
    );    

    const hasData = allDates.length > 0;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: 2,
                borderRadius: 1,
                zIndex: 10,
                boxShadow: 3,
            }}
        >
            <Typography variant="h6" align="center" sx={{ mb: 2, fontWeight: 'bold' }}>
                Data View
            </Typography>
            <TableContainer component={Paper} sx={{ maxHeight: '90%' }}>
                <Table stickyHeader aria-label="data table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig?.column === -1}
                                    direction={sortConfig?.column === -1 ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort(-1)}
                                    sx={{ minWidth: '200px' }}
                                >
                                    <Typography variant='subtitle2' fontWeight={'bold'}>Date Time</Typography>
                                </TableSortLabel>
                            </TableCell>
                            {data.map((timeseries: any, index: number) => (
                                <TableCell key={index} sx={{ width: `${(100 / data.length).toFixed(2)}%` }}>
                                    <TableSortLabel
                                        active={sortConfig?.column === index}
                                        direction={sortConfig?.column === index ? sortConfig.direction : 'asc'}
                                        onClick={() => handleSort(index)}
                                    >
                                        <Typography variant='subtitle2' fontWeight={'bold'}>
                                            {getSeriesName(timeseries, statisticTypeList)}
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortData(allDates.length ? allDates : ['No data']).map((date: string, rowIndex: number) => (
                            <TableRow key={rowIndex} sx={{ fontSize: '0.85rem' }}>
                                <TableCell component="th" scope="row" sx={{ padding: '6px' }}>
                                    {hasData
                                        ? (data[0].dataType === dataType.WaterQuality
                                            ? new Date(date).toLocaleString()
                                            : new Date(date).toLocaleDateString()
                                        )
                                        : '-'
                                    }
                                </TableCell>
                                {data.map((timeseries: any, colIndex: number) => {
                                    const value = timeseries.data?.find((point: any) => point.dateTime === date)?.value;
                                    return (
                                        <TableCell key={colIndex} sx={{ padding: '6px' }}>
                                            {value != null
                                                ? formatDigitValue(value, timeseries.dataType)
                                                : '-'}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 10,
                    top: 1,
                    right: 1
                }}
            >
                <IconButton onClick={onClose}>
                    <Cancel />
                </IconButton>
            </Box>
        </Box>
    );
};

export default DataView;
