import { Box } from '@mui/material'
import Text from '../../common/text/Text';
import React from 'react'
import { headerStyle } from './Style';
import { formatDate } from '../../../utils/Utils';
import { dataType } from '../../../pages/map-view-page/MapViewLib';
import { useMapView } from '../../../context/map-context/mapContext';

const PopupHeader = () => {
    const {selectedStation} = useMapView()
    return (
        <Box sx={headerStyle}>
            <Box sx={{ textAlign: 'left' }}>
                <Text variant="h6" sx={{ color: '#0B4566' }}>{selectedStation?.stationId}</Text>
                <Text variant="caption" sx={{ color: '#86A2B3' }}>Station</Text>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <Text variant="caption" sx={{ color: '#86A2B3', mr: 1 }}>{selectedStation?.dataType === dataType.WaterQuality ? 'Update:' : 'Sampling date & time:'} </Text>
                <Text variant="caption" sx={{ color: '#0B4566' }}>{selectedStation?.samplingDateTime !== null && formatDate(selectedStation?.samplingDateTime)}</Text>
            </Box>
        </Box>
    )
}

export default PopupHeader