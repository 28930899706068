import { SxProps, Theme } from '@mui/material/styles';

export const detailStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 1,
};

export const boxStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 2,
    boxSizing: 'border-box',
};

export const headerStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 1,
    borderBottom: '1px solid #86A2B3',
    width: '100%',
};

export const infoStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    backgroundColor: '#D8E1E7',
};

export const sensorImageStyle: SxProps<Theme> = {
    width: '140px',
    padding: '2px',
};

export const parametersStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 1,
    width: '100%',
};

export const parameterItemStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 140,
    height: 50,
    marginBottom: 2
};

export const parameterTextStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    cursor: 'pointer',
};

export const remarksStyle: SxProps<Theme> = {
    padding: 1,
};

export const footerStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: 1,
    backgroundColor: '#9eb6c2',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    position: 'relative',
    zIndex: 2
};
